import { devtools } from 'zustand/middleware';
import { ICensusItem } from '@/types.ts';
import { create } from 'zustand';
import { sdk } from '@/api.ts';

interface ICensusState {
  activeView: 'upload' | 'errors' | 'remediate' | 'verify';
  censusData: Partial<ICensusItem>[];
  censusDataWithErrors: Partial<ICensusItem>[];
  editableCensusRecordIndex: number;
  handleCensusUpload: (_file: File) => void;
  handleCensusSubmit: (cb: () => void) => void;
  handleClickCancel: () => void;
  handleClickRemediate: () => void;
  handleClickRemoveRecord: (_record: Partial<ICensusItem>) => void;
  handleSubmitEditRecord: (_record: Partial<ICensusItem>) => void;
  loading: boolean;
  setShowCancelModal: (_show: boolean) => void;
  showCancelModal: boolean;
}

export const useCensusStore = create(
  devtools<ICensusState>(
    (set, get) => ({
      activeView: 'upload',
      censusData: [],
      censusDataWithErrors: [],
      editableCensusRecordIndex: 0,
      // Upload file to "preview" the submit
      handleCensusUpload: (file) => {
        set({ loading: true });
        sdk
          .uploadCensusFile(file)
          .then((response) => {
            // List of census items without errors
            const censusData = response.data.census_data.filter(
              (item: ICensusItem) => !item.possible_errors?.length
            );
            // List of census items with errors
            const censusDataWithErrors = response.data.census_data.filter(
              (item: ICensusItem) => item.possible_errors?.length
            );
            set({
              activeView: censusDataWithErrors.length ? 'errors' : 'verify',
              censusData,
              censusDataWithErrors,
              loading: false,
            });
          })
          .catch((error) => {
            set({ loading: false });
            console.error('error: ', error);
          });
      },
      // Submit the census data once it's been reviewed and verified
      handleCensusSubmit: async (cb) => {
        const { censusDataWithErrors, censusData } = get();
        const compiledCensusData = censusData.concat(censusDataWithErrors);
        set({ loading: true });
        sdk
          .submitCensusData(compiledCensusData)
          .then(() => {
            set({
              loading: false,
              censusDataWithErrors: [],
              censusData: [],
              editableCensusRecordIndex: 0,
              activeView: 'upload',
            });
            cb();
          })
          .catch((error) => {
            console.log('error: ', error);
            set({ loading: false });
          });
      },
      handleClickRemediate: () => {
        set({ activeView: 'remediate' });
      },
      handleClickRemoveRecord: (record) => {
        const { censusDataWithErrors, editableCensusRecordIndex } = get();

        const updatedArray = censusDataWithErrors.filter((item) => {
          return JSON.stringify(item) !== JSON.stringify(record);
        });

        const nextIndex =
          editableCensusRecordIndex === updatedArray.length
            ? updatedArray.length - 1
            : editableCensusRecordIndex;

        set({
          censusDataWithErrors: updatedArray,
          editableCensusRecordIndex: nextIndex,
        });
      },
      handleClickCancel: () => {
        set({
          activeView: 'upload',
          censusData: [],
          censusDataWithErrors: [],
          editableCensusRecordIndex: 0,
          showCancelModal: false,
        });
      },
      handleSubmitEditRecord: (data) => {
        const { censusDataWithErrors, editableCensusRecordIndex } = get();
        const currentItem = censusDataWithErrors[editableCensusRecordIndex];
        const nextIndex =
          editableCensusRecordIndex < censusDataWithErrors.length - 1
            ? editableCensusRecordIndex + 1
            : 0;

        const updatedRecord = { ...currentItem, ...data };

        const updatedArray = censusDataWithErrors.map((item, index) => {
          return index === editableCensusRecordIndex ? updatedRecord : item;
        });

        set({
          censusDataWithErrors: updatedArray,
          editableCensusRecordIndex: nextIndex,
          activeView: nextIndex === 0 ? 'verify' : 'remediate',
        });
      },
      loading: false,
      showCancelModal: false,
      setShowCancelModal: (show: boolean) => set({ showCancelModal: show }),
    }),
    { devtools: false }
  )
);
