import { useEffect, useState } from 'react';
import React from 'react';
import { IMessage } from '@/components/chat/types';
import ChatResponse from '@/components/chat/chat-response';
import ChatInput from '@/components/chat/chat-input';
import ChatSuggestions from '@/components/chat/chat-suggestions';
import { AnimatePresence, motion } from 'framer-motion';
import { sdk } from '@/api.ts';
import DateSeparator from '@/components/chat/date-separator';
import Intercom from '@intercom/messenger-js-sdk';
import { useAuth } from '@/providers/auth';
import { formatDateSeparatorString } from '@/components/chat/utils.ts';

//TODO: we need to revisit auto-scrolling
const Chat = () => {
  const [userMessages, setUserMessages] = useState<IMessage[]>([]);
  const [botMessages, setBotMessages] = useState<IMessage[]>([]);
  const [messageFeed, setMessageFeed] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fadeOutSuggestions, setFadeOutSuggestions] = useState<boolean>(false);
  const [messagesLoading, setMessagesLoading] = useState<boolean>(true);
  const baseURL = `${import.meta.env.VITE_API_BASE_URL}/api/v1`;
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const full_name = user.first_name + ' ' + user.last_name;
      const createdAt =
        typeof user.created_at === 'string'
          ? Math.floor(new Date(user.created_at).getTime() / 1000)
          : user.created_at;

      Intercom({
        app_id: 's1par89m',
        user_id: user.id,
        name: full_name,
        email: user.email,
        created_at: createdAt,
      });
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);

  // Combines user and bot messages into a single feed, sorted by timestamp
  const mergeMessages = () => {
    const feed = [...userMessages, ...botMessages];
    feed.sort((a, b) => {
      return (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    });
    setMessageFeed(feed);
  };

  useEffect(() => {
    fetchAllMessages();
  }, []);

  useEffect(() => {
    mergeMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMessages, botMessages]);

  // Fetch all messages at once for the user
  const fetchAllMessages = async () => {
    try {
      const chatHistory = await sdk.getChatThreads();
      const messages: IMessage[] = chatHistory.thread_history;

      // Separate user and bot messages
      const userMessages = messages.filter(
        (msg: IMessage) => msg.user_generated_message
      );
      const botMessages = messages.filter(
        (msg: IMessage) => !msg.user_generated_message
      );

      setUserMessages(userMessages);
      setBotMessages(botMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setMessagesLoading(false);
    }
  };

  const addUserMessage = (message: string) => {
    setUserMessages([
      ...userMessages,
      {
        message_content: message,
        created_at: new Date().toISOString(),
        user_generated_message: true,
      },
    ]);
  };

  const handleSubmit = async (text: string) => {
    setFadeOutSuggestions(true);
    // If the chatbot is still "thinking", don't submit anything
    if (loading) return;

    addUserMessage(text);
    setLoading(true);
    try {
      //we are using fetch because axios doesn't support the streaming
      const url = baseURL + '/chat/messages';
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': localStorage.getItem('csrf_access_token') || '',
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({ message: text }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!response.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let botMessage = '';
      // Create a placeholder bot message
      setBotMessages((prevMessages) => [
        ...prevMessages,
        {
          message_content: '',
          created_at: new Date().toISOString(),
          user_generated_message: false,
        },
      ]);

      const readChunk = async () => {
        const { value, done } = await reader.read();
        if (done) {
          setLoading(false);
          return;
        }

        const chunk = decoder.decode(value);
        botMessage += chunk;
        setBotMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          // Update the last bot message
          updatedMessages[updatedMessages.length - 1].message_content =
            botMessage;
          return updatedMessages;
        });

        // Keep reading
        await readChunk();
      };

      await readChunk();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleClickSuggestion = async (suggestionText: string) => {
    await handleSubmit(suggestionText);
  };

  {
    /* If palermo's, show the intro message for multi-lang. otherwise, show the normal recs */
  }
  const EmptyChatState = () => {
    if (user.organization_name.toLowerCase() === 'palermos') {
      const greeting = user.first_name ? ` ${user.first_name}!` : ' there!';
      const fullMessage = `Hi${greeting} \n\nI can chat with you in English, Español (Spanish), 한국어 (Korean), or မြန်မာဘာသာ (Burmese). Just type your preferred language in the chat and we'll switch right over.`;
      return (
        <ChatResponse message={fullMessage} type={'bot'} loading={false} />
      );
    } else {
      return <ChatSuggestions onClickSuggestion={handleClickSuggestion} />;
    }
  };

  return (
    <div className="px-4 md:px-0">
      <div
        test-id="chat-message-feed"
        className="flex h-full flex-col justify-end pb-1"
      >
        {!messagesLoading && messageFeed.length === 0 && (
          <div test-id="chat-suggestions">
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 1 }}
                animate={{
                  opacity: fadeOutSuggestions ? 0 : 1,
                  display: fadeOutSuggestions ? 'none' : 'block',
                  visibility: fadeOutSuggestions ? 'hidden' : 'visible',
                }}
                transition={{ duration: 0.5, delay: 0 }}
                exit={{ opacity: 0 }}
              >
                <EmptyChatState />
              </motion.div>
            </AnimatePresence>
          </div>
        )}

        {/* Initialize lastDateString */}
        {(() => {
          let lastDateString = '';

          return messageFeed.map((item, index) => {
            const isUser = item.user_generated_message;
            const messageDate = new Date(item.created_at);
            const messageDateStr = messageDate.toISOString().split('T')[0];
            const formattedDateString = formatDateSeparatorString(messageDate);

            let dateSeparator = null;

            if (index === 0 || lastDateString !== messageDateStr) {
              const testId = `date-separator-${messageDateStr}`;
              dateSeparator = (
                <DateSeparator date={formattedDateString} testId={testId} />
              );
              lastDateString = messageDateStr;
            }

            return (
              <React.Fragment key={`msg-${index}`}>
                {dateSeparator}
                <div
                  className={`chat-message py-1 ${
                    isUser ? 'flex justify-end' : ''
                  }`}
                >
                  <div className={`flex ${isUser ? 'max-w-[60%]' : ''}`}>
                    <ChatResponse
                      type={isUser ? 'user' : 'bot'}
                      message={item.message_content}
                      loading={
                        loading && index === messageFeed.length - 1 && !isUser
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          });
        })()}
      </div>
      <div className="sticky bottom-0 rounded-xl bg-app-background pb-4">
        <ChatInput onSubmit={handleSubmit} loading={loading} />
      </div>
    </div>
  );
};

export default Chat;
