import ChatResponse from '@/components/chat/chat-response.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useOnboardingStore } from '@/routes/onboarding/store.ts';

const desiredOrder = [
  "I'm just getting started with these topics.",
  'I handle the basics but want to learn more.',
  'I confidently manage these matters.',
  'I prefer not to answer.',
];

const UserSophistication = () => {
  const {
    financialAwareness,
    setFinancialAwarenessId,
    preferredName,
    setStepIndex,
  } = useOnboardingStore();
  const secondMessage = `It's nice to meet you, ${preferredName}!
  
  How comfortable are you with managing workplace benefits and personal finances? (Don't worry — we all start somewhere!)`;

  // Since the financial awareness data response is a key/value of ids/strings, we need to sort it by the desired order
  // for the UI that the buttons need to display in
  const sortedEntries = Object.entries(
    financialAwareness as Record<string, string>
  ).sort(
    ([, valueA]: any, [, valueB]: any) =>
      desiredOrder.indexOf(valueA) - desiredOrder.indexOf(valueB)
  );

  const handleClickProficiency = (entry: any) => {
    setFinancialAwarenessId(entry[0]);
    setStepIndex(2);
  };

  return (
    <div>
      <ChatResponse message={secondMessage} type="bot" />
      <div className="space-y-4 pl-14">
        {Object.entries(sortedEntries).map((entry: any, index) => {
          const item = entry[1];
          return (
            <Button
              onClick={() => handleClickProficiency(item)}
              key={index}
              variant="outline"
              size="sm"
              className="rounded-full text-sm block"
            >
              {item[1]}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default UserSophistication;
