import { useAuth } from '@/providers/auth.tsx';
import { Navigate } from 'react-router-dom';
import OTPLogin from '@/routes/login/otp-login';
import faceLogo from '@/assets/images/face.svg';
import SplitLayout from '@/components/split-layout.tsx';

export default function Login() {
  // const [searchParams] = useSearchParams();
  // Has a user been sent here after accepting an invite?
  // const isFromAcceptedInvite = searchParams.get('invite_accepted');
  const { user, loading } = useAuth();
  // If user /auth/me hasn't finished loading, don't yet check for user
  if (loading) {
    return <></>;
  }

  if (user) {
    return <Navigate to="/app" />;
  }

  return (
    <SplitLayout
      leftContent={
        <div className="flex flex-col items-center">
          <img className="w-[60px] h-[60px]" src={faceLogo} alt="logo" />
          <h1 className="text-5xl font-semibold">Welcome Back!</h1>
        </div>
      }
      rightContent={<OTPLogin />}
    />
  );
}
