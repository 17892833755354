import { useCensusStore } from '@/routes/dashboard/census/store.ts';
import Banner from '@/components/banner.tsx';
import CensusErrorDetailForm from '@/routes/dashboard/census/census-error-detail-form.tsx';

const CensusRemediate = () => {
  const { censusDataWithErrors, editableCensusRecordIndex } = useCensusStore();

  // The item that is currently being viewed
  const currentItem = censusDataWithErrors[editableCensusRecordIndex];
  const currentItemErrors = currentItem.possible_errors || [];

  // Get a list of description strings for all errors within the current census item
  const errorDescriptions: string[] = [];
  currentItemErrors.forEach((error) => {
    errorDescriptions.push(error.error_description);
  });

  return (
    <div className="max-w-[640px] mb-8">
      <h1 className="text-2xl font-semibold">Employee Access File Upload</h1>
      <h3 className="text-lg font-semibold mt-4">Step 3</h3>
      <p>Correct issues with your employee access file.</p>
      <div className="mt-4">
        <div className="mb-2">
          <Banner
            title="There are issues with the upload file."
            description="Please edit the following records or remove them from the upload."
          />
        </div>
        <CensusErrorDetailForm
          countMessage={`Record ${editableCensusRecordIndex + 1} of ${censusDataWithErrors.length}`}
          currentItem={currentItem}
          errorDescriptions={errorDescriptions}
        />
      </div>
    </div>
  );
};

export default CensusRemediate;
