import { z } from 'zod';
import { IInvite } from '@/types.ts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';

interface AcceptInviteFormProps {
  invite: IInvite;
  onSubmit: (_data: IInvite) => void;
}

const formSchema = z.object({
  termsAccepted: z.boolean().default(false),
});

const AcceptInviteForm = ({ invite, onSubmit }: AcceptInviteFormProps) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
  });

  const termsChecked = form.watch('termsAccepted');

  const handleSubmit = async (registerData: z.infer<typeof formSchema>) => {
    setSubmitLoading(true);
    const userInviteObject = {
      ...invite,
      ...registerData,
      invite_id: invite.id,
    };
    // Pass user object back up to proceed through registration flow
    onSubmit(userInviteObject);
    setSubmitLoading(false);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <div>
          <h2 className="font-bold text-2xl">Activate Your Account</h2>
          <p className="text-muted-foreground font-medium">
            Double-check this email address – You'll use it to sign in to
            Peopled.
          </p>
        </div>
        <p className="font-semibold p-4 bg-app-background rounded">{invite.email}</p>
        <p className="text-muted-foreground font-medium">
          Before you start using Peopled, take a look at our Terms of Service
          and check the box to agree.
        </p>
        <FormField
          control={form.control}
          name="termsAccepted"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="text-md">
                  I agree to Peopled's{' '}
                  <a href="#" className="underline">
                    Terms of Service
                  </a>
                </FormLabel>
              </div>
            </FormItem>
          )}
        />
        <Button
          type="submit"
          loading={submitLoading}
          disabled={!form.formState.isValid || !termsChecked}
          className="w-full"
        >
          Continue
        </Button>
      </form>
    </Form>
  );
};

export default AcceptInviteForm;
