import { DataTable } from '@/components/data-table.tsx';
import { Card } from '@/components/ui/card.tsx';
import { columnsErrors } from '@/routes/dashboard/census/columns-errors.tsx';
import Banner from '@/components/banner.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useCensusStore } from '@/routes/dashboard/census/store.ts';

const CensusErrors = () => {
  const { censusDataWithErrors, setShowCancelModal, handleClickRemediate } =
    useCensusStore();

  return (
    <div className="max-w-[640px] ">
      <div className="mb-8">
        <h1 className="text-2xl font-semibold">Employee Access File Upload</h1>
        <h3 className="text-lg font-semibold mt-4">Step 3</h3>
        <p>
          Review uploaded information. If everything looks good, submit the file
          to complete the upload.
        </p>
        <div className="mt-4">
          <Banner
            description="Please step through and correct the errors before submission"
            title="There are issues with some of the re upload file."
          >
            <Button
              onClick={handleClickRemediate}
              variant="outline"
              size="sm"
              className="mt-2"
            >
              Step through errors
            </Button>
          </Banner>
        </div>
      </div>
      <Card>
        <DataTable columns={columnsErrors} data={censusDataWithErrors} />
      </Card>
      <div className="flex gap-x-2 mt-4">
        <Button onClick={handleClickRemediate}>Next</Button>
        <Button variant="outline" onClick={() => setShowCancelModal(true)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CensusErrors;
