import { create } from 'zustand';
import { sdk } from '@/api.ts';

interface OnboardingStore {
  preferredName?: string;
  financialAwareness?: Record<string, string>; // Financial awareness data object from API
  financialAwarenessId?: string; // ID for selected financial awareness
  stepIndex: number; // Current step in onboarding process
  setFinancialAwarenessId: (newFinancialAwarenessId: string) => void; // Set the selected financial awareness ID
  setPreferredName: (newPreferredName: string) => void; // Set the preferred name
  fetchFinancialAwareness: () => void; // Fetch financial awareness data from API and set in store
  setStepIndex: (newStepIndex: number) => void; // Set the current step in onboarding process
}

export const useOnboardingStore = create<OnboardingStore>((set) => ({
  financialAwareness: {},
  financialAwarenessId: undefined,
  preferredName: undefined,
  stepIndex: 0,
  fetchFinancialAwareness: async () => {
    const result = await sdk.getFinancialAwareness();
    set({
      financialAwareness: result.data,
    });
  },
  setFinancialAwarenessId: (newFinancialAwarenessId: string) =>
    set({ financialAwarenessId: newFinancialAwarenessId }),
  setPreferredName: (newPreferredName: string) =>
    set({ preferredName: newPreferredName }),
  setStepIndex: (newStepIndex: number) => set({ stepIndex: newStepIndex }),
}));
