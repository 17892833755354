import { ColumnDef } from '@tanstack/react-table';
import { ICensusItem } from '@/types.ts';

export const columns: ColumnDef<Partial<ICensusItem>>[] = [
  {
    accessorKey: 'first_name',
    header: 'First Name*',
  },
  {
    accessorKey: 'middle_name',
    header: 'Middle Name',
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name*',
  },
  {
    accessorKey: 'email',
    header: 'Primary Email Address*',
  },
  {
    accessorKey: 'email_secondary',
    header: 'Secondary Email Address',
  },
  {
    accessorKey: 'hire_dt',
    header: 'Hire Date*',
  },
  {
    accessorKey: 'termination_dt',
    header: 'Termination Date',
  },
  {
    accessorKey: 'rehire_dt',
    header: 'Rehire Date',
  },
  {
    accessorKey: 'title',
    header: 'Job Title',
  },
  {
    accessorKey: 'annual_salary_gross',
    header: 'Gross Salary',
  },
  {
    accessorKey: 'ytd_hours_worked',
    header: 'YTD Hours Worked',
  },
  {
    accessorKey: 'social_security_number',
    header: 'Social Security Number',
  },
  {
    accessorKey: 'date_of_birth',
    header: 'Birthdate',
  },
  {
    accessorKey: 'address_line_1',
    header: 'Street Address 1',
  },
  {
    accessorKey: 'address_line_2',
    header: 'Street Address 2',
  },
  {
    accessorKey: 'address_city',
    header: 'City',
  },
  {
    accessorKey: 'address_state',
    header: 'State',
  },
  {
    accessorKey: 'address_postal_code',
    header: 'Zip Code',
  },
  {
    accessorKey: 'phone_number_home',
    header: 'Home Phone',
  },
  {
    accessorKey: 'phone_number_mobile',
    header: 'Mobile Phone',
  },
];
