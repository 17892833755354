import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IUser } from '@/types.ts';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { statesList } from '@/lib/utils.ts';
import { sdk } from '@/api.ts';
import { useAuth } from '@/providers/auth.tsx';
import { FormTextField } from '@/components/text-field.tsx';

const formSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  email_secondary: z
    .string()
    .email({ message: 'Invalid email address' })
    .optional()
    .or(z.literal('')),
  address_line_1: z.string().optional(),
  address_line_2: z.string().optional(),
  address_city: z.string().optional(),
  address_state: z.string().optional(),
  address_postal_code: z.string().optional(),
  phone_number_home: z.string().optional(),
  phone_number_mobile: z.string().optional(),
});

export default function EditContactForm({ user }: { user: IUser }) {
  const { refreshUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: user.email,
      email_secondary: user.email_secondary || '',
      address_line_1: user.user_detail?.address_line_1 || '',
      address_line_2: user.user_detail?.address_line_2 || '',
      address_city: user.user_detail?.address_city || '',
      address_state: user.user_detail?.address_state || '',
      address_postal_code: user.user_detail?.address_postal_code || '',
      phone_number_home: user.user_detail?.phone_number_home || '',
      phone_number_mobile: user.user_detail?.phone_number_mobile || '',
    },
  });

  const onSubmit = async (formValues: z.infer<typeof formSchema>) => {
    setLoading(true);

    const formData = {
      email: formValues.email,
      email_secondary: formValues.email_secondary,
      user_detail: {
        id: user.user_detail.id,
        address_line_1: formValues.address_line_1,
        address_line_2: formValues.address_line_2,
        address_city: formValues.address_city,
        address_state: formValues.address_state,
        address_postal_code: formValues.address_postal_code,
        phone_number_home: formValues.phone_number_home,
        phone_number_mobile: formValues.phone_number_mobile,
      },
    };
    sdk
      .updateUser(formData)
      .then(() => {
        refreshUser();
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="contact-email-input"
                  type="email"
                  error={form.formState.errors.email?.message}
                  label="Email"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email_secondary"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="contact-secondary-email-input"
                  type="email"
                  error={form.formState.errors.email_secondary?.message}
                  label="Secondary Email"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone_number_home"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="contact-phone-home-input"
                  label="Home Phone Number"
                  error={form.formState.errors.phone_number_home?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone_number_mobile"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="contact-phone-mobile-input"
                  label="Mobile Phone Number"
                  error={form.formState.errors.phone_number_mobile?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address_line_1"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="contact-address-line-1-input"
                  type="text"
                  label="Address Line 1"
                  error={form.formState.errors.address_line_1?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address_line_2"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="contact-address-line-2-input"
                  type="text"
                  label="Address Line 2"
                  error={form.formState.errors.address_line_2?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address_city"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="contact-city-input"
                  type="text"
                  label="City"
                  error={form.formState.errors.address_city?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address_state"
          render={({ field }) => (
            <FormItem>
              <FormLabel>State</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <div className="flex flex-1 flex-col">
                  <FormControl>
                    <SelectTrigger test-id="contact-state-dropdown">
                      <SelectValue placeholder="State" />
                    </SelectTrigger>
                  </FormControl>
                  <FormMessage />
                </div>
                <SelectContent>
                  {statesList.map((state) => (
                    <SelectItem
                      key={state.abbreviation}
                      value={state.abbreviation}
                    >
                      {state.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address_postal_code"
          render={({ field }) => (
            <FormItem>
              <FormControl className="flex-1">
                <FormTextField
                  test-id="contact-postal-code-input"
                  label="Zip/Postal Code"
                  error={form.formState.errors.address_postal_code?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="flex gap-x-4 items-center">
          <Button disabled={loading} type="submit">
            {loading ? <LoadingSpinner /> : 'Save'}
          </Button>
          <div>
            {error && <p className="text-destructive">Something went wrong.</p>}
            {success && <p>Information updated.</p>}
          </div>
        </div>
      </form>
    </Form>
  );
}
