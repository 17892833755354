import ChatResponse from '@/components/chat/chat-response.tsx';
import PreferredNameForm from '@/routes/onboarding/preferred-name/preferred-name-form.tsx';
import { useOnboardingStore } from '@/routes/onboarding/store.ts';

const PreferredName = () => {
  const { setStepIndex, setPreferredName } = useOnboardingStore();
  const message = `Hello. Welcome to Peopled.
  
I’m an AI assistant built with privacy in mind that can help you with work benefits and financial education. I’m looking forward to working with you.

Let’s get to know each other a bit.

Which name do you prefer?
 `;

  const handleSubmitPreferredName = (data: { preferred_name: string }) => {
    setPreferredName(data.preferred_name);
    setStepIndex(1);
  };

  return (
    <div>
      <ChatResponse message={message} type="bot" />
      <div className="pl-14 max-w-[320px]">
        <PreferredNameForm handleSubmit={handleSubmitPreferredName} />
      </div>
    </div>
  );
};

export default PreferredName;
