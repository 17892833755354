import Header from '@/components/header';
import PreferredName from '@/routes/onboarding/preferred-name';
import { useOnboardingStore } from '@/routes/onboarding/store.ts';
import UserSophistication from '@/routes/onboarding/user-sophistication';
import TermsAcknowledgement from '@/routes/onboarding/terms-acknowledgement';
import { useEffect } from 'react';
import ErrorsAdviceNotice from '@/routes/onboarding/errors-advice-notice';

const Onboarding = () => {
  const { fetchFinancialAwareness, stepIndex } = useOnboardingStore();

  useEffect(() => {
    fetchFinancialAwareness();
  }, []);

  return (
    <div className="h-screen flex flex-col bg-app-background">
      <div className="flex flex-col flex-1">
        <Header hideButtons />
        <div className="flex flex-1 justify-center pr-4">
          <div className="pt-24 max-w-[634px]">
            {stepIndex === 0 ? <PreferredName /> : null}
            {stepIndex === 1 ? <UserSophistication /> : null}
            {stepIndex === 2 ? <TermsAcknowledgement /> : null}
            {stepIndex === 3 ? <ErrorsAdviceNotice /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
