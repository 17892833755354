import ChatResponse from '@/components/chat/chat-response.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useOnboardingStore } from '@/routes/onboarding/store.ts';
import { sdk } from '@/api.ts';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/providers/auth.tsx';

const ErrorsAdviceNotice = () => {
  const navigate = useNavigate();
  const { preferredName, financialAwarenessId } = useOnboardingStore();
  const { refreshUser } = useAuth();

  const message = `And finally, while I will always try to do my best, I am not perfect. Please keep in mind:

- I may occasionally generate incorrect or misleading answers. Always do your own research and double check important information. 
- This chat is not intended to give advice, including legal, financial, tax, and medical advice. Do not rely on our conversation to make decisions. 

Make sense?`;

  const handleClick = async () => {
    const updatedUserObject = {
      preferred_name: preferredName,
      onboarding_complete: true,
      user_detail: {
        financial_awareness_id: financialAwarenessId,
      },
    };
    try {
      await sdk.updateUser(updatedUserObject);
      await refreshUser();
      navigate('/app');
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };
  return (
    <div>
      <ChatResponse message={message} type="bot" />
      <div className="pl-14 max-w-[320px]">
        <Button onClick={handleClick}>Yes, let's get started.</Button>
      </div>
    </div>
  );
};

export default ErrorsAdviceNotice;
