import logo from '@/assets/images/logo/primary-orange.svg';
import { ReactNode } from 'react';

interface SplitLayoutProps {
  leftContent: ReactNode;
  rightContent: ReactNode;
}

const SplitLayout = ({ leftContent, rightContent }: SplitLayoutProps) => {
  return (
    <div className="h-screen flex">
      <div className="flex-1 flex-col bg-app-background hidden lg:flex">
        <header className="relative top-8 left-8">
          <img src={logo} alt="logo" className="max-w-[207px]" />
        </header>
        <div className="flex flex-col flex-1 justify-center items-center">
          {leftContent}
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <header className="relative left-1 top-1 lg:top-8 lg:left-8 lg:hidden">
          <img src={logo} alt="logo" className="max-w-[207px]" />
        </header>
        <div className="flex flex-col flex-1 justify-center items-center">
          <div className="max-w-[448px] w-full">{rightContent}</div>
        </div>
      </div>
    </div>
  );
};

export default SplitLayout;
