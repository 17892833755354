import { useState } from 'react';
import { sdk } from '@/api.ts';
import OTPEmailForm from '@/routes/login/otp-login/otp-email-form.tsx';
import OTPCodeForm from '@/routes/login/otp-login/otp-code-form.tsx';

const OTPLogin = () => {
  const [showCodeInput, setShowCodeInput] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleRequestCode = async (email: string) => {
    setLoading(true);
    try {
      await sdk.createOTPCode(email);
      setUserEmail(email);
      setShowCodeInput(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div>
      {showCodeInput ? (
        <OTPCodeForm
          loading={loading}
          handleRequestCode={handleRequestCode}
          email={userEmail}
        />
      ) : (
        <OTPEmailForm
          error={error}
          loading={loading}
          handleRequestCode={handleRequestCode}
        />
      )}
    </div>
  );
};

export default OTPLogin;
