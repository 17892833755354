import axios from 'axios';
import { ICensusItem, IInvite } from '@/types.ts';

const baseURL = `${import.meta.env.VITE_API_BASE_URL}/api/v1`;
const headers = {
  'Content-Type': 'application/json',
  'X-CSRF-TOKEN': localStorage.getItem('csrf_access_token'),
};

const API = axios.create({
  baseURL,
  headers,
  withCredentials: true,
});

export default API;

// @TODO some endpoints return a promise and some return the data directly
// The end goal at this moment is to return the promise so that the caller can
// handle the response states (success, error, loading) in the component itself

export const sdk = {
  getOrganization: async (id: string) => {
    return API.get(`/organizations/${id}`);
  },
  updateOrganization: async (id: string, data: any) => {
    return API.put(`/organizations/${id}`, data);
  },
  downloadOrganizationDocument: async (
    organizationDocument: string,
    documentId: string
  ) => {
    const response = await API.get(
      `/organizations/${organizationDocument}/documents/${documentId}`,
      {
        responseType: 'blob',
      }
    );

    const { headers } = response;
    const contentDisposition = headers['content-disposition'];
    const fileName = contentDisposition.split(';')[1].split('=')[1].trim();
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return response.data;
  },
  getMaritalStatuses: async () => {
    const response = await API.get('/tablelookups/marital-status-table');
    return response.data;
  },
  getFinancialAwareness: async () => {
    return await API.get('/tablelookups/financial-awareness-table');
  },
  deactivateUser: (id: string) => {
    return API.patch(`/organizations/users/${id}`, {
      account_locked: true,
    });
  },
  downgradeAdmin: (id: string) => {
    return API.patch(`/organizations/users/${id}`, {
      role: 'user',
    });
  },
  updateUser: async (data: any) => {
    const response = await API.patch(`/users/me`, data);
    return response.data;
  },
  getChatThreads: async () => {
    const response = await API.get('/chat/threads?all=true', {
      params: {
        active: true,
      },
    });
    return response.data;
  },
  downloadChatThread: async () => {
    const response = await API.get('/chat/threads/download', {
      responseType: 'blob',
      params: {
        active: true,
      },
    });
    const { headers } = response;
    const contentDisposition = headers['content-disposition'];
    const fileName = contentDisposition.split(';')[1].split('=')[1].trim();
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return response.data;
  },
  forgetChatThread: async () => {
    const response = await API.patch('/chat/threads', {});
    return response.data;
  },
  createOTPCode: async (email: string) => {
    const response = await API.post('/auth/otp', {
      email,
    });
    return response.data;
  },
  submitOTPCode: async (email: string, passcode: string) => {
    const response = await API.post('/auth/login', {
      email,
      passcode,
    });
    return response.data;
  },
  acceptInvite: (data: any) => {
    return API.post('/invites/accept', data);
  },
  getInvite: (id: string) => {
    return API.get(`/invites/${id}`);
  },
  getInvites: (opts?: any) => {
    return API.get(`/organizations/invites`, { ...opts });
  },
  createInvites: (
    data: Pick<IInvite, 'first_name' | 'last_name' | 'email'>[]
  ) => {
    return API.post('/invites', {
      invites: data,
    });
  },
  resendInvites: (invites: IInvite[]) => {
    return API.patch('/invites', {
      invites,
    });
  },
  getTheme: () => {
    return API.get('/theme');
  },
  getTerms: () => {
    return API.get('/terms-content');
  },
  // @TODO create Employee type and apply it as a Partial for the args here
  getEmployees: (opts?: any) => {
    return API.get('/census', { ...opts });
  },
  createEmployee: (data: any) => {
    return API.post('/census', data);
  },
  updateEmployee: (data: any) => {
    return API.patch(`/census`, [data]);
  },
  // This is the "verification" endpoint for census data
  uploadCensusFile: (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return API.post('/census/bulk/preview', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  // This is the "submit" endpoint for census data
  submitCensusData: (data: Partial<ICensusItem>[]) => {
    return API.post('/census/bulk', {
      census_data: data,
    });
  },
  downloadCensusTemplate: async () => {
    const response = await API.get('/census/template', {
      responseType: 'blob',
      params: {
        active: true,
      },
    });
    const { headers } = response;
    const contentDisposition = headers['content-disposition'];
    const fileName = contentDisposition.split(';')[1].split('=')[1].trim();
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return response.data;
  },
  getMetrics: () => {
    return API.get('/metrics');
  },
};
