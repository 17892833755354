import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx';
import { FormTextField } from '@/components/text-field.tsx';
import { Button } from '@/components/ui/button.tsx';

const emailFormSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
});

const OTPEmailForm = ({
  handleRequestCode,
  loading,
  error,
}: {
  error: boolean;
  handleRequestCode: (_email: string) => void;
  loading: boolean;
}) => {
  const form = useForm<z.infer<typeof emailFormSchema>>({
    resolver: zodResolver(emailFormSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (credentials: z.infer<typeof emailFormSchema>) => {
    handleRequestCode(credentials.email);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-4 px-4 lg:px-0"
      >
        <h2 className="font-semibold text-2xl">Login</h2>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="Email"
                  type="email"
                  error={form.formState.errors.email?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        {error ? (
          <p className="text-destructive my-2 text-center">
            Please check your email and try again.
          </p>
        ) : null}
        <Button type="submit" loading={loading} className="w-full">
          Login
        </Button>
      </form>
    </Form>
  );
};

export default OTPEmailForm;
