import { DataTable } from '@/components/data-table.tsx';
import { useCensusStore } from '@/routes/dashboard/census/store.ts';
import { columns } from '@/routes/dashboard/census/columns.tsx';
import { Card } from '@/components/ui/card.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useNavigate } from 'react-router-dom';

const CensusVerify = () => {
  const {
    censusData,
    censusDataWithErrors,
    setShowCancelModal,
    loading,
    handleCensusSubmit,
  } = useCensusStore();

  const navigate = useNavigate();
  const handleSubmit = () => {
    const redirect = () => navigate('/dashboard/employees');
    handleCensusSubmit(redirect);
  };

  return (
    <div className="max-w-[640px] mb-8">
      <h1 className="text-2xl font-semibold">Employee Access List Upload</h1>
      <h3 className="text-lg font-semibold mt-4">Final Step</h3>
      <p>
        Review uploaded information. If everything looks good, submit the file
        to complete the upload.
      </p>
      <Card className="mt-4">
        <DataTable
          columns={columns}
          data={censusData.concat(censusDataWithErrors)}
        />
      </Card>
      <div className="flex gap-x-2 mt-4">
        <Button loading={loading} onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="outline" onClick={() => setShowCancelModal(true)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CensusVerify;
