import { ICensusItem } from '@/types.ts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx';
import { dateRegexV2 } from '@/lib/utils.ts';
import { FormTextField } from '@/components/text-field.tsx';
import { useEffect } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { useCensusStore } from '@/routes/dashboard/census/store.ts';
import { Card } from '@/components/ui/card.tsx';

interface CensusErrorDetailFormProps {
  countMessage: string;
  currentItem: Partial<ICensusItem>;
  errorDescriptions: string[];
}

const formSchema = z.object({
  first_name: z.string().min(1, { message: 'First name is required' }),
  last_name: z.string().min(1, { message: 'Last name is required' }),
  email: z.string().email({ message: 'Invalid email address' }),
  email_secondary: z.union([
    z.string().email({ message: 'Invalid email address' }),
    z.literal(''),
  ]),
  hire_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  date_of_birth: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  termination_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
  rehire_dt: z.union([
    z.string().regex(dateRegexV2, 'Invalid date format. Use YYYY-MM-DD'),
    z.literal(''),
  ]),
});

const CensusErrorDetailForm = ({
  countMessage,
  currentItem,
  errorDescriptions,
}: CensusErrorDetailFormProps) => {
  const {
    handleClickRemoveRecord,
    setShowCancelModal,
    handleSubmitEditRecord,
  } = useCensusStore();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: currentItem?.first_name || '',
      last_name: currentItem?.last_name || '',
      email: currentItem?.email || '',
      email_secondary: currentItem?.email_secondary || '',
      hire_dt: currentItem?.hire_dt || '',
      date_of_birth: currentItem?.date_of_birth || '',
      termination_dt: currentItem?.termination_dt || '',
      rehire_dt: currentItem?.rehire_dt || '',
    },
  });

  const errorFields: string[] = [];
  currentItem.possible_errors!.forEach((error) => {
    errorFields.push(error.field_name);
  });

  // Getting a list of fields that have been modified, to compare against error
  // fields to make sure that misconfigured fields have been updated
  const dirtyFields = Object.keys(form.formState.dirtyFields);

  // Whenever a new item is loaded, reset the form with the new default values
  useEffect(() => {
    form.reset({
      first_name: currentItem?.first_name || '',
      last_name: currentItem?.last_name || '',
      email: currentItem?.email || '',
      email_secondary: currentItem?.email_secondary || '',
      hire_dt: currentItem?.hire_dt || '',
      date_of_birth: currentItem?.date_of_birth || '',
      termination_dt: currentItem?.termination_dt || '',
      rehire_dt: currentItem?.rehire_dt || '',
    });
  }, [currentItem]);

  // Comparing the error fields to the dirty fields to determine if the form is
  // modified and ready to be submitted
  const correctFieldsHaveBeenModified = () => {
    errorFields.sort();
    dirtyFields.sort();
    if (errorFields.length !== dirtyFields.length) {
      return false;
    }
    return errorFields.every((value, index) => value === dirtyFields[index]);
  };

  const onSubmit = async (data: Partial<ICensusItem>) => {
    handleSubmitEditRecord(data);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card>
            <div className="mb-4">
              <h3 className="text-lg semibold">{countMessage}</h3>
              <ul className="list-disc text-sm ml-4">
                {errorDescriptions.map((description, i) => (
                  <li key={i}>{description}</li>
                ))}
              </ul>
            </div>
            <dl className="detail-dl-form">
              <dt>First Name</dt>
              {errorFields.includes('first_name') ? (
                <FormField
                  control={form.control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <FormTextField
                          className="mt-[-1.75rem]"
                          test-id="first-name-input"
                          error={form.formState.errors.first_name?.message}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              ) : (
                <dd>{currentItem.first_name}</dd>
              )}

              <dt>Last Name</dt>
              {errorFields.includes('last_name') ? (
                <FormField
                  control={form.control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <FormTextField
                          className="mt-[-1.75rem]"
                          test-id="last-name-input"
                          error={form.formState.errors.last_name?.message}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              ) : (
                <dd>{currentItem.last_name}</dd>
              )}

              <dt>Email Address</dt>
              {errorFields.includes('email') ? (
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <FormTextField
                          className="mt-[-1.75rem]"
                          test-id="email-input"
                          error={form.formState.errors.email?.message}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              ) : (
                <dd>{currentItem.email}</dd>
              )}

              <dt>Secondary Email</dt>
              {errorFields.includes('email_secondary') ? (
                <FormField
                  control={form.control}
                  name="email_secondary"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <FormTextField
                          className="mt-[-1.75rem]"
                          test-id="email-secondary-input"
                          error={form.formState.errors.email_secondary?.message}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              ) : (
                <dd>{currentItem.email_secondary || '-'}</dd>
              )}

              <dt>Hire Date</dt>
              {errorFields.includes('hire_dt') ? (
                <FormField
                  control={form.control}
                  name="hire_dt"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <FormTextField
                          className="mt-[-1.75rem]"
                          test-id="hire-date-input"
                          error={form.formState.errors.hire_dt?.message}
                          helperText="YYYY-MM-DD"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              ) : (
                <dd>{currentItem.hire_dt}</dd>
              )}

              {/* @TODO we need to dynamically add all fields here that aren't in */}
              {/* the design, and apply their dl/dd/dt + input if they're in the */}
              {/* possible errors list. ex: rehire date, term date, phone, + others */}
              {/* Manually hardcoding them now but this feels capital D _dirty_ */}
              {errorFields.includes('date_of_birth') ? (
                <>
                  <dt>Date of Birth</dt>
                  <FormField
                    control={form.control}
                    name="date_of_birth"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <FormTextField
                            className="mt-[-1.75rem]"
                            test-id="date-of-birth-input"
                            error={form.formState.errors.date_of_birth?.message}
                            helperText="YYYY-MM-DD"
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </>
              ) : null}
              {errorFields.includes('termination_dt') ? (
                <>
                  <dt>Termination Date</dt>
                  <FormField
                    control={form.control}
                    name="termination_dt"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <FormTextField
                            className="mt-[-1.75rem]"
                            test-id="termination-date-input"
                            error={
                              form.formState.errors.termination_dt?.message
                            }
                            helperText="YYYY-MM-DD"
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </>
              ) : null}
              {errorFields.includes('rehire_dt') ? (
                <>
                  <dt>Rehire Date</dt>
                  <FormField
                    control={form.control}
                    name="rehire_dt"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <FormTextField
                            className="mt-[-1.75rem]"
                            test-id="rehire-date-input"
                            error={form.formState.errors.rehire_dt?.message}
                            {...field}
                            helperText="YYYY-MM-DD"
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </>
              ) : null}
              <dt></dt>
              <dd>
                <Button
                  onClick={() => handleClickRemoveRecord(currentItem)}
                  variant="outline"
                  type="button"
                >
                  Remove Record
                </Button>
              </dd>
            </dl>
          </Card>
          <div className="mt-4 flex gap-x-2">
            <Button type="submit" disabled={!correctFieldsHaveBeenModified()}>
              Next
            </Button>
            <Button
              type="button"
              variant="outline"
              onClick={() => setShowCancelModal(true)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CensusErrorDetailForm;
