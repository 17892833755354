import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx';
import { FormTextField } from '@/components/text-field.tsx';
import { Button } from '@/components/ui/button.tsx';

const formSchema = z.object({
  preferred_name: z.string().min(1, 'Preferred name is required'),
});

const PreferredNameForm = ({
  handleSubmit,
}: {
  handleSubmit: (data: { preferred_name: string }) => void;
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      preferred_name: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    handleSubmit(data);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <FormField
          control={form.control}
          name="preferred_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="onboarding-preferred-name-input"
                  error={form.formState.errors.preferred_name?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit">Next</Button>
      </form>
    </Form>
  );
};

export default PreferredNameForm;
