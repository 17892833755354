import { format } from 'date-fns';

export function formatDateSeparatorString(date: Date) {
  const now = new Date();
  const isToday = now.toDateString() === date.toDateString();

  if (isToday) {
    return 'Today';
  }
  // date-fns 'do' returns the "ordinal time" (e.g. 1st, 2nd, 3rd, 4th, etc.)
  return format(date, 'MMMM do, yyyy');
}
