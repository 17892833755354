export interface DateSeparatorProps {
  date: string;
  testId: string;
}

const DateSeparator = ({ date, testId }: DateSeparatorProps) => {
  return (
    <div className="flex items-center my-4" data-testid={testId}>
      <div className="flex-grow border-t border-foreground"></div>
      <span className="mx-4 text-sm font-medium">{date}</span>
      <div className="flex-grow border-t border-foreground"></div>
    </div>
  );
};

export default DateSeparator;
